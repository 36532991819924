table td, table td {
    white-space: nowrap;
    font-size: 13px;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 5px !important;
}

.ant-form-item .ant-form-item-label >label {
    font-size: 12px !important;
}
.ant-typography.ant-typography-edit-content {
    height: 31px !important;
}
.center-label .ant-form-item-label {
    display: flex !important;
    justify-content: center !important;
}
.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #f0f0f0;
}
.ant-picker-datetime-panel {
    flex-direction: column !important;
    align-items: center !important;
}
.ant-picker-dropdown .ant-picker-datetime-panel .ant-picker-time-panel {
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-segmented-item-selected {
    background-color: #3e79f7 !important; 
    color: #ffffff !important;
}
  
.ant-segmented-thumb {
    background-color: #3e79f7 !important;
}
.ant-segmented .ant-segmented-item {
    width: 100% !important;
}

.parlay-betSlip-border li {
    border: 1px solid #57575794;
    border-top: none;
}
.parlay-betSlip-border li:first-child {
    border-top: 1px solid #57575794 !important;
}

.roleType .ant-radio-button-wrapper {
    padding-inline: 5px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 560px) {
    .ant-picker-panels {
        display: block !important;
        width: 310px !important;
    }
}
